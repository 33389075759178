<template>
  <div
    v-if="true"
    v-loading="loading"
  >

    <page-heading
      :heading="procedure.name"
    >
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>

    <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad cupiditate error eveniet, exercitationem iusto laudantium quos sapiente sequi similique suscipit ullam voluptas. Animi aperiam autem blanditiis corporis distinctio doloremque earum enim eum excepturi exercitationem expedita fuga fugiat id laborum magnam maiores minus nemo, neque nisi nobis quaerat quo quos repellendus tempora temporibus totam vero voluptatem? Asperiores dicta, dolore est fuga harum laboriosam non perferendis porro praesentium quae quo soluta voluptas, voluptates? Ad aliquam architecto asperiores autem cumque dicta doloremque eligendi esse, ex exercitationem fugiat fugit ipsam maxime modi molestias nihil nulla numquam obcaecati, quaerat quis quod sed sunt temporibus, voluptatem voluptates! Asperiores atque cum cumque deleniti ducimus, ea eos esse ex minima obcaecati officiis pariatur totam unde? Adipisci aliquid eligendi ipsam modi perspiciatis. Accusantium asperiores deserunt, dolorem eligendi id ipsum iste labore libero, magni numquam odit officiis pariatur porro praesentium, quo repellat reprehenderit soluta sunt temporibus tenetur. Id, natus, quisquam. Ducimus est eum fugit, nobis nulla quasi quia repellendus sapiente temporibus vero. A ab aliquam amet architecto aut cumque debitis dolor dolorem ducimus error excepturi, exercitationem facilis fugit harum ipsa minus necessitatibus neque nobis non provident, quae quam quas quia quidem reiciendis, soluta suscipit totam ullam unde veniam. Corporis, facere?</div>

    <div
      v-if="!$canAndRulesPass(user, 'compensations.procedure_year_bonus_tree_see_all')"
      style="margin: 30px 0"
    >
      <i class="fas fa-info-circle"></i>
      Нажмите на ФИО подчиненного руководителя для просмотра премий его сотрудников
    </div>

    <el-tree
      class="procedure-users-tree"
      empty-text="Нет пользователей"
      :load="loadTreeNode"
      lazy
      ref="procedureUsersTree"
      node-key="id"
      :expand-on-click-node="false"
      v-loading="loadingRootUser"
      @node-click="userClicked"
    >
      <div class="procedure-users-tree-node" slot-scope="{ node, data }">
        <div class="procedure-users-tree-node-name">
          <div class="procedure-users-tree-node-name-position">{{ data.user.position }}</div>
          <div class="procedure-users-tree-node-name-full-name">{{ data.user.full_name }}</div>
        </div>
        <div class="procedure-users-tree-node-status">
          <el-tag v-if="data.agreed" type="success">Ознакомлен</el-tag>
          <el-tag v-else type="danger">Не ознакомлен</el-tag>
        </div>
      </div>
    </el-tree>


  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import PageHeading from "@/components/PageHeading";
import {mapGetters} from "vuex";

export default {
  name: 'CompensationProcedureTree',
  components: {
    PageHeading,
  },
  mixins: [],
  computed: {
    ...mapGetters(['user']),
  },
  watch: {},
  data() {
    return {
      loading: false,
      loadingRootUser: false,

      procedure: {},

    }
  },
  mounted() {
    this.loadProcedure();
  },
  methods: {
    loadProcedure() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/get', {
        id: parseInt(this.$route.params.id),
        expand: ['meta'].join(),
      })
        .then(data => {
          this.procedure = data.procedure;
        })
        .finally(() => {
          this.loading = false;
        });
    },


    loadTreeNode(node, resolve) {
      this.loadingRootUser = !node.data;
      requestSender('get', 'compensation-procedure-year-bonus/get-users-tree', {
        procedure_id: this.$route.params.id,
        parent_user_id: node.data ? node.data.user_id : null,
        expand: ['user'].join(),
      })
        .then(data => {
          this.loadingRootUser = false;
          return resolve(data.procedure_users)
        })
    },
    userClicked(node, nodeOfTree, treeNode) {
      if( this.$canAndRulesPass(this.user, 'compensations.procedure_year_bonus_tree_see_all') ){
        return;
      }
      const { href } = this.$router.resolve({ name: 'CompensationProcedureView', params: { procedure_id: this.$route.params.id, owner_id:node.user_id } } );
      window.open(href, '_blank');
    },

  }
}
</script>

<style lang="scss">
.procedure-users-tree{

  &-node{
    display: flex;
    justify-content: space-between;
    gap: 40px;

    &-name{
      margin-left: 10px;

      &-position{
        font-size: .8rem;
        color: gray;
      }
      &-full-name{
        font-weight: bold;
      }
    }
  }

  & .el-tree-node__content{
    height: 50px !important;

    & .el-tree-node__expand-icon{
      font-size: 25px;
    }
  }

  //&-node{
  //  height:100px;
  //}
}
</style>